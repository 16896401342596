import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';
import BaseServices from './baseServices';

export default {
  add: async (params) => {
    try {
      const files = params.files;
      const url = api.voucher.add;
      let results = [];
      // upload each image in a request
      for(const file of files) {
        const fileUpload = [file];
        fileUpload.companyId = params.companyId;
        const data = await BaseServices.postDataTokenForm(url, fileUpload);
        const result = await data.json();
        results = [...results, result];
      }
      return results;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  update: async (params) => {
    try {
      const url = api.voucher.update.replace(':id', params.voucherId);
      const data = await BaseServices.putDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getYears: async (companyId) => {
    try {
      const params = httpHelper.serialize({
        companyId,
      });
      const url = `${api.voucher.getYears}?${params}`;
      const data = await BaseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getStatistic: async (params) => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.voucher.getStatistic}?${queryParams}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  fetchAll: async (params) => {
    // companyId, month, year, keyword, source, paymentStatus, limit, page
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.voucher.get}?${queryParams}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  deleteVoucherBySelectedRowKeys: async (payload) => {
    try {
      const url = `${api.voucher.deleteVoucherBySelectRowKeys}`;
      const data = await BaseServices.postDataToken(url, payload);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  updatePayment: async (params) => {
    try {
      const url = `${api.voucher.updatePayment}${params.Id}`;
      const data = await BaseServices.putDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getVoucherById: async (id) => {
    try {
      const url = `${api.voucher.get}/${id}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  updateMissing: async (id, formData) => {
    try {
      let index = 0;
      const form = {
        isPrivate: formData.isPrivate,
        note: formData.note,
      };
      for (let i = 0; i < formData.files.length; i++) {
        if (formData.files[i] != null) {
          form[`files[${index++}]`] = formData.files[i];
        }
      }
      const data = await BaseServices.putDataTokenForm(api.voucher.updateMissing + id, form);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getPdfFile: (path) => {
    return api.file + path;
  },
  getFilDownload: (path) => {
    return api.download + path;
  },
  crawlVoucherEmail: async (params) => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.voucher.crawlVoucherEmail}?${queryParams}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  delete: async (id) => {
    try {
      let url = api.voucher.delete;
      url = url.replace(':id', id);
      const data = await BaseServices.deleteDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getVoucherBalance: async (params) => {
    const queryParams = httpHelper.serialize(params);
    const url = `${api.voucher.getVoucherBalance}?${queryParams}`;
    const data = await BaseServices.getDataToken(url);
    const result = await data.json();
    return result;
  },
  addPayment: async (params) => {
    try {
      const url = api.voucher.addPayment;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  addToPaymentList: async (params) => {
    try {
      const url = api.voucherPayment.addToPaymentList;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  removePayment: async (params) => {
    try {
      const url = api.voucher.removePayment;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getMissingSummary: async (params) => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.voucher.getMissingSummary}?${queryParams}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getForwardedVoucher: async (params) => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.voucher.getForwardedVoucher}?${queryParams}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  fetchAdminVoucher: async (params) => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.voucher.fetchAdminVoucher}?${queryParams}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  updatePaymentStatus: async (params) => {
    try {
      const url = api.voucher.updatePaymentStatus;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getVoucherSuggestInfo: async (voucherId) => {
    try {
      const query = httpHelper.serialize({ voucherId });
      const url = `${api.voucher.getVoucherSuggestInfo}?${query}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  addVoucherSuggestion: async (body) => {
    try {
      const url = api.voucherAI.addSuggestion;
      const data = await BaseServices.postDataToken(url, body);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
